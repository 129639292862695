import React, { useEffect, useState } from 'react';
import http from 'Utils/http';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { setAPIs } from 'Actions/APIs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

const LoggingSearchForm = ({ statisticsList, setFilteredStatisticsList, setPage, setFilter }) => {
    const dispatch = useDispatch();
    const apiList = useSelector((state) => state.APIs.APIs);

    const [totalCalls, setTotalCalls] = useState(null);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(DateTime.now());

    useEffect(() => {
        setTotalCalls(statisticsList.reduce((sum, elem) => sum + Number(elem.calls), 0));
    }, [statisticsList]);

    useEffect(() => {
        const cachedIntegrations = sessionStorage.getItem('integrations');
        if (cachedIntegrations) {
            dispatch(setAPIs(JSON.parse(cachedIntegrations)));
            return;
        }
        dispatch(setLoading(true));
        http.get('/integrations').then((res) => {
            dispatch(setAPIs(res.data.integrations));
            dispatch(setLoading(false));
        }).catch(() => {
            dispatch(setLoading(false));
        });
    }, [dispatch]);

    const {
        register,
        handleSubmit,
        setValue,
        control
    } = useForm();

    const clearHandler = () => {
        setValue('email', '');
        setValue('flow', '');
        setValue('API', 'all');

        setDateFrom(null);
        setDateTo(DateTime.now());

        setTotalCalls(
            statisticsList.reduce(function (sum, elem) {
                return sum + Number(elem.calls);
            }, 0)
        );
        setPage(0);
        setFilter({});
    };

    const searchHandler = (data) => {
        let filter = {};
        if (data.email) {
            filter.email = data.email.toLowerCase().trim();
        }

        if (data.API && data.API !== 'all') {
            filter.integrationName = data.API;
        }

        if (dateTo) {
            filter.to = dateTo.toISODate();
        }

        if (dateFrom) {
            filter.from = dateFrom.toISODate();
        }

        setPage(0);
        setFilter(filter);
    };

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit(searchHandler)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                        label="Date from"
                        value={dateFrom}
                        maxDate={dateTo}
                        onChange={(newValue) => setDateFrom(newValue)}
                        slotProps={{textField: {size: 'small'}}}
                        sx={{
                            marginRight: '16px',
                        }}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                        label="Date to"
                        value={dateTo}
                        maxDate={DateTime.now()}
                        onChange={(newValue) => setDateTo(newValue)}
                        slotProps={{textField: {size: 'small'}}}
                        sx={{
                            marginRight: '16px',
                        }}
                    />
                </LocalizationProvider>

                <TextField
                    label="Search by email"
                    size="small"
                    sx={{
                        marginRight: '16px',
                    }}
                    {...register('email', {})}
                />

                <Controller
                    name="API"
                    control={control}
                    defaultValue={'all'}
                    render={({field: {onChange, value}}) => (
                        <FormControl
                            sx={{
                                marginRight: '24px',
                            }}
                        >
                            <InputLabel>API</InputLabel>
                            <Select
                                value={value}
                                onChange={onChange}
                                label="API"
                                style={{minWidth: 120}}
                                size="small"
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {apiList.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                />

                <Button
                    sx={{
                        marginRight: '16px',
                    }}
                    variant="contained"
                    type="submit"
                >
                    Search
                </Button>
                <Button sx={{marginRight: '16px'}} onClick={clearHandler} variant="outlined">
                    Clear
                </Button>

                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: '500',
                        textAlign: 'center',
                        color: '#3B465C',
                    }}
                >
                    Total calls: {totalCalls}
                </Typography>
            </Box>
        </>
    );
};

export default LoggingSearchForm;

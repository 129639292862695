import React, { useEffect, useState } from 'react';
import http from 'Utils/http';
import Editor from 'react-simple-code-editor';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { highlight, languages } from 'prismjs/components/prism-core';
import { useSelector, useDispatch } from 'react-redux';
import { Box, FormControl, TextField, IconButton, FormHelperText } from '@mui/material';
import { setLoading } from 'Actions/loading';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { CustomSnackbar } from 'Shared/CustomSnackbar';

const isValidJSON = (jsonString) => {
    return jsonString.startsWith('{') || jsonString.startsWith('[') || jsonString.startsWith('<');
}

export const CreateInputField = ({ inputs, setNeedRefresh }) => {
    const namesArray = inputs.map(item => item.name);
    const dispatch = useDispatch();

    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const flow_id = useSelector((state) => state.flow.flow?.id);
    const [type, setType] = useState('FIELD');
    const [code, setCode] = useState('');

    const {
        register,
        control,
        watch,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            defaultValue: ''
        },
        mode: 'onChange'
    });

    useEffect(() => {
        const subscription = watch((values) => {
            const { defaultValue } = values;
            const isType = isValidJSON(defaultValue);
            setType(isType ? 'OBJECT' : 'FIELD');

            if (isType) {
                setCode(defaultValue);
            } else {
                setCode('');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, setType, setValue, setCode]);

    const onSubmit = (data) => {
        if (namesArray.indexOf(data.parameterName) !== -1) {
            enqueueSnackbar('Adding parameters with the same name is not allowed. The parameter name must be unique within the section', {
                action: CustomSnackbar,
                variant: 'error'
            });
            return false;
        }
        const params = {
            flow_id: flow_id,
            name: data.parameterName,
            default: data.defaultValue
        };
        dispatch(setLoading(true));
        http.post(createUrl(`/input/${flow_id}/field/create`), params).then(() => {
            enqueueSnackbar('New input parameter has been created', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setNeedRefresh(true);
            setValue('parameterName', '');
            setValue('defaultValue', '');
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '16px',
                }}
            >
                <FormControl
                    sx={{
                        width: '100%',
                        marginRight: '10px'
                    }}
                >
                    <TextField
                        sx={{
                            backgroundColor: '#F2F5F8'
                        }}
                        label="Parameter Name"
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        {...register('parameterName', {
                            required: true,
                        })}
                    />
                </FormControl>

                <FormControl sx={{ width: '100%', marginRight: '10px' }}>
                    <Controller
                        name="defaultValue"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) =>
                                type === 'OBJECT' ? isValidJSON(value) || 'Invalid JSON format' : true
                        }}
                        render={({ field }) => (
                            <>
                                {type === 'OBJECT' ? (
                                    <Editor
                                        {...field}
                                        tabSize={4}
                                        padding={10}
                                        value={code}
                                        id="defaultValue"
                                        highlight={(code) => highlight(code, languages.js)}
                                        onValueChange={(code) => setCode(code)}
                                        style={{
                                            borderRadius: '4px',
                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            backgroundColor: '#F2F5F8',
                                            minHeight: '100px',
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        {...field}
                                        type="text"
                                        id="defaultValue"
                                        label="Default Value"
                                        sx={{ marginBottom: '16px' }}
                                        error={!!errors?.defaultValue}
                                        helperText={errors?.defaultValue ? 'This is a required field' : ''}
                                    />
                                )}
                                {errors.defaultValue && (
                                    <FormHelperText>{errors.defaultValue.message}</FormHelperText>
                                )}
                            </>
                        )}
                    />
                </FormControl>

                <IconButton
                    color="primary"
                    sx={{
                        marginRight: '53px',
                        width: '53px',
                        height: '53px',
                    }}
                    onClick={handleSubmit(onSubmit)}
                >
                    <DataSaverOnIcon/>
                </IconButton>
            </Box>
        </Box>
    );
};

import React, { useEffect, useState } from 'react';
import http from 'Utils/http';
import { Box, Grid, Typography, Paper, InputBase, IconButton } from '@mui/material';
import DeleteImg from 'Assets/img/delete.png';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { AlertDialog } from 'Shared/AlertDialog';
import { CustomSwitch } from 'Shared/CustomSwitch';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { AccordionCustomIcon } from 'Shared/Icons';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import TagsList from 'Shared/tags/TagsList';
import FlowItemFieldRows from 'Shared/flows/flows-interface/FlowItemFieldRows';

const FlowItemAccordion = ({ item, tags, updateFlow }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { createUrl } = useCreateUrl();

    const integrationDefault = useSelector((state) => state?.APIs?.APIs?.find((api) => api.id === item?.method?.integration_id)) ?? {};

    const { method } = item;
    const { request, response } = method;

    const [expanded, setExpanded] = useState('');
    const [search, setSearch] = useState('');
    const [activeSwitch, setActiveSwitch] = useState('all');

    // Default rows data
    const [fieldsListDefault, setFieldsListDefault] = useState({request, response});
    const [filteredFieldsList, setFilteredFieldsList] = useState({ request, response });

    // Update fields list after update FlowMethodList
    useEffect(() => {
        setFieldsListDefault({request, response});
        setFilteredFieldsList({request, response});
    }, [request, response, setFilteredFieldsList, setFieldsListDefault]);

    const getLengthFieldsRow = (request, response) => {
        return Math.max(request.length, response.length);
    };

    const [rowsCount, setRowsCount] = useState(getLengthFieldsRow(request, response));

    const { register, handleSubmit } = useForm();

    // Expanded accordion
    const toggleList = () => {
        setExpanded((prevExpanded) => (prevExpanded === 'active' ? 'inactive' : 'active'));
    };

    // Update rows count
    useEffect(() => {
        setRowsCount(getLengthFieldsRow(filteredFieldsList.request, filteredFieldsList.response));
    }, [filteredFieldsList]);

    // Sorter by switch
    useEffect(() => {
        let filteredRequest;
        let filteredResponse;

        switch (activeSwitch) {
            case 'not-matched':
                filteredRequest = fieldsListDefault.request.filter(item => !item?.sourceTransformation);
                filteredResponse = fieldsListDefault.response.filter(item => !item?.sourceTransformation);
                break;
            case 'matched':
                filteredRequest = fieldsListDefault.request.filter(item => item?.sourceTransformation);
                filteredResponse = fieldsListDefault.response.filter(item => item?.sourceTransformation);
                break;
            default:
                filteredRequest = fieldsListDefault.request;
                filteredResponse = fieldsListDefault.response;
                break;
        }

        filteredRequest = filteredRequest.filter((item) => {
            return item.name.toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1;
        });

        filteredResponse = filteredResponse.filter((item) => {
            return item.name.toString().toLowerCase().indexOf(search.toString().toLowerCase()) !== -1;
        });

        setFilteredFieldsList({
            request: filteredRequest,
            response: filteredResponse,
        });
    }, [search, activeSwitch, fieldsListDefault]);

    // Search by input value
    const searchHandler = (data) => {
        setActiveSwitch('all');
        setSearch(data.searchValue);
    };

    // Remove method
    const removeHandler = () => {
        dispatch(setLoading(true));
        http.delete(createUrl(`/flow/method/${item.id}/unlink`)).then(() => {
            updateFlow();
        }).catch(err => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    // AlertDialog actions
    const modalText = 'Are you sure you want to delete the method?';
    const [isActiveModal, setIsActiveModal] = useState(false);
    const onClose = () => setIsActiveModal(false);
    const onAgree = () => {
        setIsActiveModal(false);
        removeHandler();
    };

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText}/>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box>
                    <Box
                        sx={{
                            marginBottom: '10px',
                            backgroundColor: '#FAFEFF',
                            border: '1px solid #FFFFFF',
                            borderTop: 'none',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                        }}
                    >
                        <Box>
                            <Box
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '22px 27px',
                                    position: 'relative',
                                    background: 'url("/assets/img/test.svg") 50% 100%',
                                    backgroundSize: 'cover',
                                    borderTopLeftRadius: '16px',
                                    borderTopRightRadius: '16px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        marginBottom: '10px',
                                        textAlign: 'center',
                                        fontWeight: 700,
                                        fontSize: '15px',
                                        lineHeight: '18px',
                                        letterSpacing: '0.15em',
                                        wordBreak: 'break-word',
                                        color: '#949DB0',
                                    }}
                                >
                                    {integrationDefault?.name || ''} | {item.method.name}
                                </Typography>

                                <Paper
                                    component="form"
                                    onSubmit={handleSubmit(searchHandler)}
                                    className="custom-search-input"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        height: '43px',
                                        marginBottom: '30px',
                                        padding: '10px 10px 10px 15px',
                                        background: '#F2F5F8',
                                        border: '2px solid rgba(65, 77, 101, 0.25)',
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <InputBase
                                        sx={{
                                            width: '100%',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            color: '#949DB0',
                                        }}
                                        placeholder="Search..."
                                        {...register('searchValue', {})}
                                    />
                                    <IconButton
                                        type="submit"
                                        aria-label="search"
                                        sx={{
                                            p: '0px',
                                        }}
                                    >
                                        <SearchIcon/>
                                    </IconButton>
                                </Paper>

                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                    }}
                                    onClick={() => {
                                        toggleList();
                                    }}
                                >
                                    <AccordionCustomIcon/>
                                </IconButton>
                            </Box>

                            <Box sx={{ padding: '40px 25px', position: 'relative' }}>
                                <CustomSwitch activeSwitch={activeSwitch} setActiveSwitch={setActiveSwitch}/>
                                <IconButton
                                    onClick={() => {
                                        setIsActiveModal(true)
                                    }}
                                    type="button"
                                    aria-label="remove"
                                    sx={{
                                        p: '0px',
                                        position: 'absolute',
                                        right: '10px',
                                        bottom: '10px',
                                        '&:hover': {
                                            opacity: '0.5'
                                        }
                                    }}
                                >
                                    <img
                                        style={{
                                            width: '20px',
                                            height: '20px'
                                        }}
                                        src={DeleteImg}
                                        alt=""
                                    />
                                </IconButton>
                            </Box>


                            <Box sx={{ position: 'relative' }}>
                                {/* position absolute */}
                                <Box className={expanded || 'inactive'}
                                     sx={{
                                         width: '100%',
                                         boxShadow: '1px 0px 0px #FFFFFF, -1px 0px 0px #FFFFFF',
                                         borderBottomLeftRadius: '16px',
                                         borderBottomRightRadius: '16px',
                                         overflowY: 'auto',
                                     }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '13px 35px',
                                            background: '#E8EDF4',
                                            borderBottom: '1px solid rgba(148, 157, 176, 0.25)',
                                            position: 'sticky',
                                            top: '0'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: '700',
                                                fontSize: '13px',
                                                lineHeight: '18px',
                                                letterSpacing: '-0.078px',
                                                color: '#414D65',
                                            }}
                                        >
                                            Input
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontWeight: '700',
                                                fontSize: '13px',
                                                lineHeight: '18px',
                                                letterSpacing: '-0.078px',
                                                color: '#414D65',
                                            }}
                                        >
                                            Output
                                        </Typography>
                                    </Box>

                                    {/* grid */}

                                    <FlowItemFieldRows
                                        request={filteredFieldsList.request}
                                        response={filteredFieldsList.response}
                                        rowsCount={rowsCount}
                                        itemInfo={item}
                                        integrationName={integrationDefault?.name || ''}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <TagsList tagsList={tags} index={item.id} />
            </Grid>
        </>
    );
};

export default FlowItemAccordion;

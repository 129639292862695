import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Switch,
    IconButton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { AlertDialog } from 'Shared/AlertDialog';
import FlowsGraph from 'Shared/flows/FlowsGraph';
import FlowCopyForm from 'Shared/flows/flows-interface/FlowCopyForm';
import { CopyIcon } from 'Shared/Icons';
import { Box } from "@mui/system";

export const MyFlowsItem = ({item, needUpdate,  unlinkFlow, deleteFlow, changeStatusFlow}) => {
    const navigate = useNavigate();

    const deleteText = 'Are you sure you want to delete your flow?';
    const unlinkText = 'Are you sure you want to delete flow in your list?';
    const changeText = 'Are you sure you want to change status flow?';

    const [expanded, setExpanded] = useState(false);
    const [modalAction, setModalAction] = useState('change');
    const [modalText, setModalText] = useState('Are you sure you want to change status flow?');
    const [visibleCopyFlowForm, setVisibleCopyFlowForm] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        if (event.target.classList.contains('PrivateSwitchBase-input')) {
            return;
        }
        if (event.target.classList.contains('MuiButtonBase-root')) {
            return;
        }
        setExpanded(isExpanded ? panel : false);
    };

    const handleChangeSwitch = (e) => {
        e.preventDefault();
        setModalText(changeText);
        setModalAction('change');
        setIsActiveModal(true);
    };

    const handleDelete = (e, id, status) => {
        e.stopPropagation();
        setModalText(deleteText);
        setModalAction('delete');
        setIsActiveModal(true);
    };

    const handleCopy = (e) => {
        e.stopPropagation();
        setVisibleCopyFlowForm(true);
    };

    const handleUnlink = (e) => {
        e.stopPropagation();
        setModalText(unlinkText);
        setModalAction('unlink');
        setIsActiveModal(true);
    };

    const [isActiveModal, setIsActiveModal] = useState(false);
    const onClose = () => setIsActiveModal(false);
    const onAgree = () => {
        setIsActiveModal(false);
        if (modalAction === 'unlink') {
            unlinkFlow(item);
        }
        if (modalAction === 'delete') {
            deleteFlow(item);
        }
        if (modalAction === 'change') {
            const foo = {
                ...item,
                status: item.status === 'active' ? 'disabled' : 'active',
            };
            changeStatusFlow(foo);
        }
    };

    const toFlow = (event, id) => {
        event.preventDefault();
        navigate(`/flow/${id}`);
    };

    return (
        <>
            <AlertDialog visible={isActiveModal} onClose={onClose} onAgree={onAgree} text={modalText}/>
            <FlowCopyForm id={item.id} needUpdateList={needUpdate} setVisibleFlowForm={setVisibleCopyFlowForm} visibleFlowForm={visibleCopyFlowForm}/>
            <Accordion
                sx={{
                    marginBottom: '20px',
                }}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary
                    sx={{
                        backgroundColor: '#F2F5F8',
                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',
                        },
                    }}
                    expandIcon={
                        <IconButton
                            onClick={(e) => {
                                toFlow(e, item.id);
                            }}
                        >
                            <SettingsIcon/>
                        </IconButton>
                    }
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#3B465C',
                            marginRight: '20px',
                            flexShrink: 0,
                        }}
                    >
                        {item?.name}
                    </Typography>

                    {item.access === 'user' ? (
                        <>
                            <Switch
                                sx={{
                                    marginLeft: 'auto',
                                }}
                                checked={item.status === 'active'}
                                color="primary"
                                onClick={(e) => handleChangeSwitch(e)}
                            />
                            <IconButton
                                sx={{
                                    marginRight: '14px',
                                    color: '#f44336',
                                }}
                                onClick={(e) => {
                                    handleDelete(e, item.id, item.status);
                                }}
                                aria-label="delete"
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </>
                    ) : (
                        <Box
                            sx={{
                                marginLeft: 'auto',
                                marginRight: '14px'
                            }}>
                            <IconButton
                                sx={{
                                    marginRight: '14px',
                                    color: expanded ? '#F4B156' : '',
                                }}
                                aria-label="open-chart"
                            >
                                <StackedBarChartIcon/>
                            </IconButton>
                            <IconButton
                                sx={{
                                    marginRight: '14px',
                                    color: '#f44336',
                                }}
                                onClick={(e) => {
                                    handleCopy(e, item.id, item.status);
                                }}
                                aria-label="copy"
                            >
                                <CopyIcon/>
                            </IconButton>
                            <IconButton
                                sx={{
                                    marginRight: '14px',
                                    color: '#f44336',
                                }}
                                onClick={(e) => {
                                    handleUnlink(e, item.id, item.status);
                                }}
                                aria-label="delete"
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    )}
                </AccordionSummary>
                <AccordionDetails>
                    {expanded ? (<FlowsGraph item={item}/>) : null}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

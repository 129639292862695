import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Box, FormControl, InputLabel, Select, MenuItem, IconButton, FormHelperText } from '@mui/material';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import http from 'Utils/http';
import { useCreateUrl } from 'Hooks/useCreateUrl';

export const SelectApiField = ({ setNeedRefresh }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { createUrl } = useCreateUrl();

    const flow_id = useSelector((state) => state.flow.flow?.id);
    const apiListRedux = useSelector((state) => state.APIs.APIs);
    const flowMethods = useSelector((state) => state.flowMethodList.flowMethodList);
    const currentUserId = useSelector((state) => state.auth.user.id);

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues: {
            api: '',
            method: '',
            field: ''
        },
        mode: 'onChange'
    });

    const [linkedIntegrations, setLinkedIntegrations] = useState([]);
    const [filteredNotSystemMethodsOnUI, setFilteredNotSystemMethodsOnUI] = useState([]);
    const [fieldsList, setFieldsList] = useState([]);

    useEffect(() => {
        if (Object.keys(flowMethods).length !== 0) {
            const uniqueIntegrationArrayIds = [...new Set(flowMethods.map(item => item.method.integration_id))]
                .map(item => Number(item));
            const apiArray = apiListRedux.filter(api => uniqueIntegrationArrayIds.includes(api.id));
            setLinkedIntegrations(apiArray);
        }
    }, [flowMethods, flow_id, apiListRedux]);

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'api') {
                setValue('method', '');

                const filteredMappedOutputs = flowMethods.filter(item => item.method.integration_id === value.api)
                    .map(({ id, method }) => ({ flow_method_id: id, ...method }))
                    .filter(item => item.name !== 'Output Port');

                if (!filteredMappedOutputs.length) {
                    setFilteredNotSystemMethodsOnUI([]);
                    setFieldsList([]);
                    setValue('field', '');
                    return;
                }

                setFilteredNotSystemMethodsOnUI(filteredMappedOutputs);
                setFieldsList([]);
                setValue('field', '');
                return;
            }

            if (name === 'method') {
                setFieldsList([]);
                setValue('field', '');
                if (getValues('method')) {
                    http.get(createUrl(`/flow/method/${value.method}/get`)).then((res) => {
                        const fields = res.data.flowMethod.method.response;
                        setFieldsList(fields);
                    }).catch((err) => {
                        enqueueSnackbar(`${err.response.data.message}`, {
                            action: CustomSnackbar,
                            variant: 'error'
                        });
                    });
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, apiListRedux, createUrl, flowMethods, enqueueSnackbar, getValues, setValue]);

    const onSubmit = (data) => {
        const methodValue = getValues('method');

        const params = {
            flow_id: flow_id,
            field_id: data.field,
            user_id: currentUserId,
            source_flow_method_id: methodValue
        };

        dispatch(setLoading(true));
        http.post(createUrl(`/output/${flow_id}/field/create`), params).then(() => {
            setValue('api', '');
            setValue('method', '');
            setValue('field', '');
            enqueueSnackbar('New output port has been created', {
                action: CustomSnackbar,
                variant: 'success'
            });
            setNeedRefresh(true);
        }).catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}} component="form" onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    marginBottom: '16px',
                }}
            >
                <FormControl sx={{ width: '100%', marginRight: '10px' }} error={!!errors.api}>
                    <InputLabel>API</InputLabel>
                    <Controller
                        name="api"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                sx={{ backgroundColor: '#F2F5F8' }}
                                label="API"
                            >
                                {linkedIntegrations.map((item) => (
                                    <MenuItem key={`${item.id}api`} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.api && <FormHelperText>This is a required field</FormHelperText>}
                </FormControl>

                {/* method */}
                <FormControl sx={{ width: '100%', marginRight: '10px' }} error={!!errors.method}>
                    <InputLabel>Method</InputLabel>
                    <Controller
                        name="method"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                sx={{ backgroundColor: '#F2F5F8' }}
                                label="Method"
                            >
                                {filteredNotSystemMethodsOnUI.map((item) => (
                                    <MenuItem key={`${item.flow_method_id}method`} value={item.flow_method_id}>
                                        {item.name} ({item.flow_method_id})
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.method && <FormHelperText>This is a required field</FormHelperText>}
                </FormControl>

                {/* field */}
                <FormControl sx={{ width: '100%', marginRight: '10px' }} error={!!errors.field}>
                    <InputLabel>Field</InputLabel>
                    <Controller
                        name="field"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select
                                {...field}
                                sx={{ backgroundColor: '#F2F5F8' }}
                                label="Field"
                            >
                                {fieldsList.map((item) => (
                                    <MenuItem key={`${item.id}field`} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.field && <FormHelperText>This is a required field</FormHelperText>}
                </FormControl>

                <IconButton
                    color="primary"
                    sx={{
                        width: '53px',
                        height: '53px',
                    }}
                    onClick={handleSubmit(onSubmit)}
                >
                    <DataSaverOnIcon/>
                </IconButton>
            </Box>
        </Box>
    );
};

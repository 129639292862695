import React from 'react';
import { Box } from '@mui/material';
import { TagItem } from 'Shared/tags/TagItem';

const TagsList = ({ tagsList, index }) => {
    // Get unique combinations of integration_id and method_id
    const uniqueCombinations = [];
    Object.keys(tagsList).forEach(key => {
        tagsList[key].forEach(item => {
            if (uniqueCombinations.indexOf(`${item.integration_id}-${item.method_id}`) === -1) {
                uniqueCombinations.push(`${item.integration_id}-${item.method_id}`);
            }
        });
    });

    // Define color based on index in uniqueCombinations
    const getColor = index => {
        const colors = ['#F4B156', '#E07B6A', '#c191ff', '#2FDE8A',
            '#FF5733', '#1fa3d2', '#00CED1', '#FFD700', '#7CFC00',
            '#FF6347', '#8B4513', '#00FA9A', '#FF69B4', '#4169E1',
            '#ad4cc5', '#FF4500', '#20B2AA', '#FF8C00', '#9932CC',
            '#00BFFF', '#48D1CC', '#FFDAB9', '#DDA0DD', '#40E0D0'];
        return colors[index % colors.length];
    };

    const mappedTagsList = tagsList[index]?.map(item => ({
        text: `${item.integration_name} | ${item.method_name}`.toUpperCase(),
        color: getColor(uniqueCombinations.indexOf(`${item.integration_id}-${item.method_id}`)),
    }));

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            {mappedTagsList?.map((item, index) => (
                <TagItem key={index} text={item.text} color={item.color} />
            ))}
        </Box>
    );
};

export default TagsList;

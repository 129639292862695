import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'Actions/loading';
import { Grid, Box, Typography, Button, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import { AdminFlowsItem } from 'Components/shared/flows/AdminFlowsItem';
import { AdminFlowsItemV2 } from 'Components/shared/flows/AdminFlowsItemV2';
import FlowCreateForm from 'Shared/flows/flows-interface/FlowCreateForm';
import { AddIcon } from 'Shared/Icons';
import Logo_PNG from 'Assets/img/logo_png.png';
import http from 'Utils/http';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useSnackbar } from 'notistack';
import { useCreateUrl } from 'Hooks/useCreateUrl';

const AdminFlowsPage = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { createUrl } = useCreateUrl();

    const [flowsList, setFlowsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [userFlowList, setUserFlowList] = useState([]);
    const [user, setUser] = useState('');
    const [visibleCreateFlowForm, setVisibleCreateFlowForm] = useState(false);
    const [needUpdateList, setNeedUpdateList] = useState(true);

    const toCreateFlow = (e) => {
        e.preventDefault();
        setVisibleCreateFlowForm(true);
    };

    const getPreDefinedFlowsList = useCallback(async () => {
        try {
            dispatch(setLoading(true));
            const res = await http.get('/flows');
            const filtered = res.data.flows.filter((flow) => flow.access === 'public');
            setFlowsList(filtered);
            dispatch(setLoading(false));
        } catch (err) {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }
    }, [dispatch, enqueueSnackbar]);

    const setUserAndGetFlows = (user_id) => {
        setUser(user_id);
        getFlowUser(user_id);
    };

    const getFlowUser = (user_id) => {
        if (!user_id) {
            enqueueSnackbar(`Please select user`, {
                action: CustomSnackbar,
                variant: 'warning'
            });
            return;
        }

        dispatch(setLoading(true));
        http.get(createUrl(`/user/flow/list`))
            .then((res) => {
                const mapped = res.data.flows.map((item) => {
                    return {
                        id: item.flow_id,
                        user_id: user_id,
                        ...item,
                    };
                });

                setUserFlowList(mapped);
                dispatch(setLoading(false));
            }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const deleteFlow = async (item) => {
        try {
            dispatch(setLoading(true));
            await http.delete(`/flow/${item.id}/delete`);

            await getPreDefinedFlowsList();

            if (user) {
                await getFlowUser(user);
            }

            dispatch(setLoading(false));
            enqueueSnackbar('Flow was deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
        } catch (err) {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        }
    };

    const deleteFlowUser = (item) => {
        const user_id = item.user_id;
        const url = createUrl(`/flow/${item.id}/delete`);
        dispatch(setLoading(true));
        http.delete(url).then(() => {
            getPreDefinedFlowsList();
            getFlowUser(user_id);
            dispatch(setLoading(false));
            enqueueSnackbar('Flow was deleted', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    const changeStatusFlow = (item) => {
        const params = {
            flow_id: item.id,
            status: item.status
        };
        dispatch(setLoading(true));
        http.post(`/flow/${item.id}/update`, params).then(() => {
            getPreDefinedFlowsList();
            dispatch(setLoading(false));
            enqueueSnackbar('Flow status was changed', {
                action: CustomSnackbar,
                variant: 'success'
            });
        }).catch((err) => {
            dispatch(setLoading(false));
            enqueueSnackbar(`${err.response.data.message}`, {
                action: CustomSnackbar,
                variant: 'error'
            });
        });
    };

    useEffect(() => {
        http.get('/users').then((res) => {
            setUsersList(res.data.users);
        }).catch(() => {});
    }, []);

    useEffect(() => {
        if (needUpdateList) {
            getPreDefinedFlowsList().then(() => setNeedUpdateList(false));
        }
    }, [needUpdateList, getPreDefinedFlowsList, setNeedUpdateList]);

    return (
        <>
            {visibleCreateFlowForm && (
                <FlowCreateForm
                    visibleCreateFlowForm={visibleCreateFlowForm}
                    setVisibleCreateFlowForm={setVisibleCreateFlowForm}
                />
            )}
            <Grid container sx={{height: '100%'}}>
                <Grid item
                      xs={12}
                      md={4}
                      lg={3}
                      sx={{
                          paddingBottom: '20px',
                          '@media (min-width: 1024px)': {
                              paddingRight: '20px',
                              paddingBottom: '0',
                          },
                      }}>
                    <Box
                        sx={{
                            height: '100%',
                            backgroundColor: '#f9f7f5',
                            border: '1px solid #FFFFFF',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        <img style={{width: '100%'}} src={Logo_PNG} alt=""/>
                    </Box>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                    <Box
                        sx={{
                            height: '100%',
                            padding: '20px',
                            backgroundColor: '#FAFEFF',
                            border: '1px solid #FFFFFF',
                            boxShadow: '1px 3px 15px rgba(0, 0, 0, 0.1)',
                            borderRadius: '16px',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h2"
                            color="primary.blackText"
                            sx={{
                                marginBottom: '20px',
                                textAlign: 'center',
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '24px',
                            }}
                        >
                            Available Pre-Defined Flows:
                        </Typography>

                        {flowsList.map((item) => (
                            <AdminFlowsItem
                                key={`${item.name}-${item.id}`}
                                item={item}
                                deleteFlow={() => deleteFlow(item)}
                                changeStatusFlow={changeStatusFlow}
                                needUpdate={setNeedUpdateList}
                            />
                        ))}

                        <Button
                            sx={{
                                marginTop: '26px',
                            }}
                            onClick={(e) => {
                                toCreateFlow(e);
                            }}
                            startIcon={<AddIcon/>}
                            variant="outlined"
                        >
                            Create New Flow
                        </Button>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '26px',
                            }}
                        >
                            <Typography
                                variant="h2"
                                color="primary.blackText"
                                sx={{
                                    marginBottom: '20px',
                                    textAlign: 'center',
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                }}
                            >
                                User's Flows:
                            </Typography>

                            <FormControl
                                sx={{
                                    justifySelf: 'center',
                                    minWidth: '300px',
                                    marginBottom: '16px',
                                }}
                            >
                                <InputLabel>Select User</InputLabel>
                                <Select
                                    defaultValue=""
                                    value={user}
                                    onChange={(e) => setUserAndGetFlows(e.target.value)}
                                    label="Select User"
                                >
                                    {usersList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.email}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {userFlowList.map((item) => (
                                <AdminFlowsItemV2
                                    key={item.id}
                                    item={item}
                                    deleteFlow={() => deleteFlowUser(item)}
                                    changeStatusFlow={changeStatusFlow}
                                />
                            ))}

                            {userFlowList.length === 0 && user && (
                                <Typography
                                    sx={{
                                        marginBottom: '20px',
                                        fontWeight: 500,
                                        fontSize: '20px',
                                        lineHeight: '24px',
                                        color: '#D76A6A',
                                    }}
                                >
                                    User flows list is empty
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AdminFlowsPage;

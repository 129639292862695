import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { Switch } from '@mui/material';
import { CardMedia } from '@mui/material';
import { AlertDialog } from 'Shared/AlertDialog';
import { UserIntegrationCreds } from 'Shared/integrations/UserIntegrationCreds';

export const UserIntegrationItem = ({ item, unlinkIntegration }) => {
  const origin = window.location.origin;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    if (event.target.classList.contains('PrivateSwitchBase-input')) {
      return;
    }
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeSwitch = (e) => {
    e.preventDefault();
    setIsActiveModal(true);
  };

  const [isActiveModal, setIsActiveModal] = useState(false);
  const onClose = () => setIsActiveModal(false);
  const onAgree = (e) => {
    unlinkIntegration();
  };

  return (
    <>
      <AlertDialog
        visible={isActiveModal}
        onClose={onClose}
        onAgree={onAgree}
        text={'Are you sure you want to delete integration in your list?'}
      />

      <Accordion
        sx={{
          marginBottom: '20px',
        }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          sx={{
            backgroundColor: '#F2F5F8',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            },
          }}
          expandIcon={<SettingsIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            variant="h6"
            sx={{
              color: '#3B465C',
              marginRight: '20px',
              flexShrink: 0,
            }}
          >
            {item?.name}
          </Typography>
          <CardMedia
            component="img"
            image={`${origin}${item.logo}`}
            sx={{
              maxWidth: '40px',
              marginRight: '20px',
            }}
          />

          <Typography sx={{ color: 'text.secondary' }}>{item?.description}</Typography>

          <Switch
            sx={{
              marginLeft: 'auto',
            }}
            checked
            color="primary"
            onClick={(e) => handleChangeSwitch(e)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <UserIntegrationCreds item={item} unlinkIntegration={unlinkIntegration} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
